<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Taman </strong><small>Edit Data Taman</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateTaman">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="name"
                  label="Nama Taman"
                  placeholder="Input Nama Taman"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="address"
                  label="Alamat Taman"
                  placeholder="Alamat Taman"
                />  
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label>Kecamatan</label>
                <select v-model="kecamatan" placeholder="Pilih Kecamatan" class="form-control">
                  <option value="" disabled selected>Pilih Kecamatan</option>
                  <option value="Pontianak Kota">Pontianak Kota</option>
                  <option value="Pontianak Timur">Pontianak Timur</option>
                  <option value="Pontianak Barat">Pontianak Barat</option>
                  <option value="Pontianak Utara">Pontianak Utara</option>
                  <option value="Pontianak Selatan">Pontianak Selatan</option>
                  <option value="Pontianak Tenggara">Pontianak Tenggara</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="luas"
                  label="Luas (M2)"
                  placeholder="Input Luas Taman"
                />
              </CCol>
              <!-- <CCol sm="6">
                <CInput
                  v-model="persentase_luas"
                  label="Persentase Luas"
                  placeholder="Input Persentase Luas"
                />
              </CCol> -->
              <CCol sm="6">
              <CInput
                v-model="lng"
                label="Longitude"
                placeholder="Input Longitude"
              />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="lat"
                  label="Lattitude"
                  placeholder="Input Lattitude"
                />  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Foto Taman </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
            </CRow>
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/taman">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
      
    >
      <b>Error</b>! Data Taman gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      luas : "",
      name : "",
      persentase_luas : "",
      address : "",
      kecamatan : "",
      lng : "",
      lat : "",
      file_text: "",
      file: "",
      placeholder: "",
      selected : [], // Must be an array reference!
      show: true,
      modalError: false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showTaman();
  this.loadData();
  },
  methods: {

    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_text = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      // axios.get(process.env.VUE_APP_BASE_URL+"api-sippohon/public/taman")
      //   .then((response) => {
      //         this.pohon=response.data;
      //   })
    },

    updateTaman: function(event){
      // Simple POST request with a JSON body using axios
      const formData = new FormData()
      
      // formData.append('id_informasi_pohon', this.$route.params.id_informasi_pohon)
      formData.append('name', this.name)
      formData.append('address', this.address)
      formData.append('kecamatan', this.kecamatan)
      formData.append('luas', this.luas)
      formData.append('lng', this.lng)
      formData.append('lat', this.lat)

      if (this.file_text) {
          // alert(this.foto_informasi_pohon);
        formData.append('file', this.file, this.file.name)      
        formData.append('file_text', this.file.name)
      }
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"taman/"+this.$route.params.id, formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.$router.push('/taman');
            }
            else{
              this.modalError = true;
            }
            
        })
    },
    showTaman: function(event){
      // Simple POST request with a JSON body using axios
      const taman = { 
                          luas:this.luas,
                          name:this.name,
                          persentase_luas: this.persentase_luas, 
                          address: this.address, 
                          kecamatan: this.kecamatan, 
                          lng: this.lng, 
                          foto2: this.foto2, 
                          lat: this.lat
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"taman/"+this.$route.params.id)
        .then((response) => {
              const data = response.data.data;
              this.luas=data.luas;
              this.name=data.name;
              this.persentase_luas=data.persentase_luas;
              this.address=data.address;
              this.kecamatan=data.kecamatan;
              this.lng=data.lng;
              this.lat=data.lat; 
              this.placeholder=data.foto2;
        })
    }
  }
}
</script>